import styled from 'styled-components';
import CircleIcon from '@mui/icons-material/Circle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


export const ContainerButtonsMenu = styled.div`
    flex-grow: 1;
    display: flex;
`;

export const BtnMenu = styled.div<{margin?: string}>`
    display: flex;
    flex-direction: column;
    margin: ${p => p.margin ? p.margin : 0};
    cursor: pointer;
`;

export const BtnMenuText = styled.span`
    margin: 12px 0 4px 0;
    font-size: 14pt;
`;

export const IconCircle = styled(CircleIcon)`
    margin: auto;
    font-size: 1pt;
`;

export const Img = styled.img`
    width: 50px;
    padding: 4px;
    
    filter: brightness(0) invert(1);;
    -webkit-filter: brightness(0) invert(.95);
`;

export const CustomAccountCircleIcon = styled(AccountCircleIcon)`
    color: white;
    font-size: 24pt !important;
`;
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import * as s from './totalizers.style';
import * as g from '../../../global.style';


interface Props {
    executeds: number;
    forwardeds: number;
    autoPacts: number;
    executedsPercentMAC: number | string;
    forwarderPercentMAC: number | string;
    autoPactsPercentMAC: number | string;
    isPercentMAC: boolean;

}


const Totalizers = (props: Props) => {
    let mainValueExecuteds: string
    let mainValueForwarder: string
    let mainValueAutoPacts: string
    let secondaryValueExecuteds: string
    let secondaryValueForwarder: string
    let secondaryValueAutoPacts: string
    let mainSpan: string;
    let secondarySpan: string;
    
    let mainMoneySymbol: string;
    let secondaryMoneySymbol: string;

    if (props.isPercentMAC) {
        mainValueExecuteds = `${props.executedsPercentMAC}%`;
        mainValueForwarder = `${props.forwarderPercentMAC}%`;
        mainValueAutoPacts = `${props.autoPactsPercentMAC}%`;


        secondaryValueExecuteds = `${props.executeds}`;
        secondaryValueForwarder = `${props.forwardeds}`;
        secondaryValueAutoPacts = `${props.autoPacts}`;
        
        mainMoneySymbol = "";
        secondaryMoneySymbol =  "R$";
        mainSpan = " do teto MAC";
        secondarySpan = "";
        
    } else {
        mainValueExecuteds = `${props.executeds}`;
        mainValueForwarder = `${props.forwardeds}`;
        mainValueAutoPacts = `${props.autoPacts}`;

        secondaryValueExecuteds = `${props.executedsPercentMAC !== null ? props.executedsPercentMAC : '--'}%`;
        secondaryValueForwarder = `${props.forwarderPercentMAC !== null ? props.forwarderPercentMAC : '--'}%`;
        secondaryValueAutoPacts = `${props.autoPactsPercentMAC !== null ? props.autoPactsPercentMAC : '--'}%`;

        mainSpan = "";
        secondarySpan = " do teto MAC";
        mainMoneySymbol = "R$";
        secondaryMoneySymbol =  "";
    }

    return (
        <s.Container>
            <g.RowBetween>
                <s.CardTotalizer>
                    <s.CardTotalizerContent>
                        <s.CardTotalizerTitle color="green">
                            Total Executado
                        </s.CardTotalizerTitle>
                        
                        <s.CardTotalizerValueContainer>
                            <s.IconCenter color="green" bg="rgba(0, 128, 0, .2)">
                                <CallReceivedIcon></CallReceivedIcon>
                            </s.IconCenter>
                            
                            <g.Col>
                                <s.CardTotalizerMainValue>
                                    <s.MiniSpan>{ mainMoneySymbol }</s.MiniSpan>
                                    <s.SpanMainValue>{ mainValueExecuteds }</s.SpanMainValue>
                                    <s.MiniSpan>{ mainSpan }</s.MiniSpan>
                                </s.CardTotalizerMainValue>

                                <s.CardTotalizerSecondaryValue>
                                    <span>{ secondaryMoneySymbol }</span>
                                    <b>{ secondaryValueExecuteds }</b>
                                    <span>{ secondarySpan }</span>
                                </s.CardTotalizerSecondaryValue>
                            </g.Col>
                        </s.CardTotalizerValueContainer>
                    </s.CardTotalizerContent>

                    <s.CardTotalizerBottomRibbon color="green"></s.CardTotalizerBottomRibbon>
                </s.CardTotalizer>

                <s.CardTotalizer margin="0 8px">
                    <s.CardTotalizerContent>
                        <s.CardTotalizerTitle color="red">
                            Total Encaminhado
                        </s.CardTotalizerTitle>
                        
                        <s.CardTotalizerValueContainer>
                            <s.IconCenter color="red" bg="#ff000033">
                                <ArrowOutwardIcon></ArrowOutwardIcon>
                            </s.IconCenter>
                            
                            <g.Col>
                                <s.CardTotalizerMainValue>
                                    <s.MiniSpan>{ mainMoneySymbol }</s.MiniSpan>
                                    <s.SpanMainValue>{ mainValueForwarder }</s.SpanMainValue>
                                    <s.MiniSpan>{ mainSpan }</s.MiniSpan>
                                </s.CardTotalizerMainValue>

                                <s.CardTotalizerSecondaryValue>
                                    <span>{ secondaryMoneySymbol }</span>
                                    <b>{secondaryValueForwarder}</b>
                                    <span>{ secondarySpan }</span>
                                </s.CardTotalizerSecondaryValue>
                            </g.Col>
                        </s.CardTotalizerValueContainer>
                    </s.CardTotalizerContent>

                    <s.CardTotalizerBottomRibbon color="red"></s.CardTotalizerBottomRibbon>
                </s.CardTotalizer>

                <s.CardTotalizer>
                    <s.CardTotalizerContent>
                        <s.CardTotalizerTitle>
                            Total Auto Pactuado
                        </s.CardTotalizerTitle>
                        
                        <s.CardTotalizerValueContainer>
                            <s.IconCenter>
                                <AutorenewIcon></AutorenewIcon>
                            </s.IconCenter>
                            
                            <g.Col>
                                <s.CardTotalizerMainValue>
                                    <s.MiniSpan>{ mainMoneySymbol }</s.MiniSpan>
                                    <s.SpanMainValue>{mainValueAutoPacts}</s.SpanMainValue>
                                    <s.MiniSpan>{ mainSpan }</s.MiniSpan>
                                </s.CardTotalizerMainValue>
                                <s.CardTotalizerSecondaryValue>
                                    <span>{ secondaryMoneySymbol }</span>
                                    <b>{secondaryValueAutoPacts}</b>
                                    <span>{ secondarySpan }</span>
                                </s.CardTotalizerSecondaryValue>
                            </g.Col>
                        </s.CardTotalizerValueContainer>
                    </s.CardTotalizerContent>

                    <s.CardTotalizerBottomRibbon></s.CardTotalizerBottomRibbon>
                </s.CardTotalizer>
            </g.RowBetween>
        </s.Container>
    )
}

export default Totalizers
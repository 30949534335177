import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { COLORS } from '../common';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

interface Props {
    AACA: number;
    AMCR: number;
    AMCA: number;
    HACA: number;
    HMCR: number;
    HMCA: number;
    isPercentMAC: boolean;
}

 
export default function VBar(props: Props) {

    let options = {
        responsive: true,
        maintainAspectRatio: false,
        borderRadius: 100,
        barPercentage: 0.1,
        layout: {
          padding: {
            top: 50
          }
        },
        plugins: {
          legend: {
            display: false
          },
          title: {
            display: false,
          },
          datalabels: {
            display: true,
            font: {
              size: 18,
              weight: "bold" as any
            },
            color: [COLORS["AACA"], COLORS["AMCR"], COLORS["AMCA"], COLORS["HACA"], COLORS["HMCR"], COLORS["HMCA"]],
            formatter: (val: number) => props.isPercentMAC? `% ${val}` : `R$ ${val}`,
            anchor: "end" as const,
            align: "end" as const
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        }
      };
    
  
    const labels = ['AACA', 'AMCR', 'AMCA', 'HACA', 'HMCR', 'HMCA'];


    
    let data = {
        labels,
        datasets: [
          {
            label: '',
            data: [props.AACA, props.AMCR, props.AMCA, props.HACA, props.HMCR, props.HMCA],
            backgroundColor: [COLORS["AACA"], COLORS["AMCR"], COLORS["AMCA"], COLORS["HACA"], COLORS["HMCR"], COLORS["HMCA"]],
          }
        ],
    };

    return <Bar options={options} data={data} height={'100%'}/>;
  // return <Bar options={options} data={data}/>;
}
import './App.css';
import 'leaflet/dist/leaflet.css';

import { MonitoringPage } from './pages/monitoring';
import { Charts } from './pages/charts';
import LoginPage from './pages/login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  RecoilRoot,
} from 'recoil';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import { checkAPIAvailability } from './api/app';
import { useNavigate } from 'react-router-dom';
import MonitoringDetails from './pages/monitoringDetails';


const App = ()  => {

  return (
    <RecoilRoot>
        <ToastContainer />
        <BrowserRouter>
          <AppRoutes/>
        </BrowserRouter>
    </RecoilRoot>
  )
}

const AppRoutes = () => {

  const navigate = useNavigate()

  useEffect(
    () => {
      let accessToken = sessionStorage.getItem("accessToken")

      if (!accessToken)
        navigate("/login")

      checkAPIAvailability( () => navigate("/login") )
    }, [navigate]
  )

  return (
    <Routes>
      <Route path="/" element={<MonitoringPage />}/>
      <Route path="/login" element={<LoginPage />}/>
      <Route path="/monitoring" element={<MonitoringPage />}/>
      <Route path="/monitoring/details" element={<MonitoringDetails />}/>
      <Route path="/charts" element={<Charts />}/>
    </Routes>
  )
}


export default App;

import { useLocation, useNavigate } from "react-router-dom";
import Navbar, { MenuItems } from "../../components/Navbar"
import { MonitoringDetail, MonitoringRow, SIADetail, SIHDetail } from "../monitoring/interfaces";
import * as s from './style';
import * as g from '../../global.style'
import * as sg from '../../styles/components'

import HandshakeIcon from '@mui/icons-material/Handshake';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import TableChartIcon from '@mui/icons-material/TableChart';
import { getDetails } from "../monitoring/api";
import { useEffect, useState } from "react";
import * as st from '../monitoring/components/Table.style'
import { Button, Drawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import { StyledTableRow } from "../monitoring/components/Table";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import WarningIcon from '@mui/icons-material/Warning';
import { dtFormatted, valOrNull } from "../../helpers/formatters";
import GiteIcon from '@mui/icons-material/Gite';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// TODO: pass this function getDetails for monitoringDetails


const MonitorinDetails = () => {
    const location = useLocation()
    const monitoring: MonitoringRow = location.state
    const navigate = useNavigate()
    
    const [details, setDetails] = useState<MonitoringDetail>()
    const [valTotalAprovado, setValTotalAprovado] = useState<number | null>(null)

    const [openInfoDrawer, setOpenInfoDrawer] = useState(false)

    const getTotalAprovado = (sia?: SIADetail[]): number | null => {
        if (sia){
            let totalAprovado = 0

            for (let row of sia) {
                totalAprovado += row?.val_aprovado ? row.val_aprovado : 0
            }

            return totalAprovado
        }
        return null
    }

    useEffect(() => {
        getDetails(
            (data: MonitoringDetail) => {
                setDetails(data)
                setValTotalAprovado(
                    getTotalAprovado(data.sia)
                )
            },
            monitoring.cod_proc,
            monitoring.cod_mun_encaminhador,
            monitoring.cod_mun_executor
        )
    }, [setDetails, monitoring.cod_proc, monitoring.cod_mun_encaminhador, monitoring.cod_mun_executor])

    

    const defineSiaIcon = (): any => {
        if (valTotalAprovado === null){
            return <HorizontalRuleIcon style={{margin: "auto"}}/>
        }

        if (details?.val_financeiro && valTotalAprovado > details?.val_financeiro) {
            return (
                <div title="Somatório do Valor Aprovado é maior que o Valor Financeiro" style={{margin: "auto"}}>
                    <WarningIcon style={{color: "red"}}/>
                </div>
            )
        }
        else if (details?.val_financeiro && valTotalAprovado <= details?.val_financeiro) {
            return (
                <div title="Somatório do Valor Aprovado é menor que o Valor Financeiro" style={{margin: "auto"}}>
                    <CheckCircleIcon style={{color: "green"}}/>
                </div>
            )
        }
        else {
            <HorizontalRuleIcon/>
        }
    }

    return (
        <s.Container>
            <Navbar activedMenu={MenuItems.monitoring}/>

            <s.ContainerContent>
                <g.RowBetween>

                    <g.Row>
                        <g.Row style={{cursor: "pointer"}} onClick={() => navigate("/monitoring")}>
                            <g.ColCenter>
                                <ArrowBackIosIcon/>
                            </g.ColCenter>

                            <sg.Title margin="8px 6px">Detalhamento</sg.Title>
                        </g.Row>

                        <g.ColCenter>
                            <Button variant="contained" onClick={() => setOpenInfoDrawer(true)}>SIA / SIH</Button>
                        </g.ColCenter>
                    </g.Row>

                    {/* <sg.CloseIcon style={{margin: "auto 0"}} onClick={() =>  navigate("/monitoring")}/> */}
                </g.RowBetween>

                <g.RowBetween>
                    <s.ColContent>
                        <sg.CardBase>
                            <sg.CardTitle>
                                Pactuação
                                <HandshakeIcon color="primary" style={{"margin": s.ICON_MARGIN}}/>
                            </sg.CardTitle>
                        </sg.CardBase>

                        <s.Card flexGrow={1}>
                            <ul>
                                <sg.Li>
                                    <sg.LiTitle>Código</sg.LiTitle>
                                    <sg.LiText>
                                        {valOrNull(details?.cod_proc)}
                                    </sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Procedimento</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_proc)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Nom. Segmento</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_segmento)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Mun. Encaminhador</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_mun_encaminhador)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Mun. Executor</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_mun_executor)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Qtd. Física</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.qtd_fisica)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Val. Unitário</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.val_unitario, true)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Val. Financeiro</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.val_financeiro, true)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Nom. Leito</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_leito)}</sg.LiText>
                                </sg.Li>
                            </ul>
                        </s.Card>
                    </s.ColContent>

                    <s.ColContent>
                        <sg.CardBase>
                            <sg.CardTitle>
                                SIGTAP
                                <TableChartIcon color="secondary" style={{"margin": s.ICON_MARGIN}}/>
                            </sg.CardTitle>
                        </sg.CardBase>
                        
                        <s.Card flexGrow={1}>
                            <ul>
                                <sg.Li>
                                    <sg.LiTitle>Des. Proc</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.des_proc)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Cod. Grupo</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.cod_grupo_proc)}</sg.LiText>
                                </sg.Li>
                                
                                <sg.Li>
                                    <sg.LiTitle>Des. Grupo</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.des_grupo_proc)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Des. Subproc</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.des_subproc)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Cod. Grupo Subproc</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.cod_grupo_subproc)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Des. Grupo Subproc</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.des_grupo_subproc)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Nom. Especialidade</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_especialidade)}</sg.LiText>
                                </sg.Li>

                                <sg.Li>
                                    <sg.LiTitle>Nom. Subespecialidade</sg.LiTitle>
                                    <sg.LiText>{valOrNull(details?.nom_subespecialidade)}</sg.LiText>
                                </sg.Li>
                            </ul>
                        </s.Card> 
                    </s.ColContent>
                </g.RowBetween>
            </s.ContainerContent>

            <Drawer
                PaperProps={{
                    sx: {
                      backgroundColor: "#f1f1f1",
                    }
                  }}
                anchor={"left"}
                open={openInfoDrawer}
                onClose={ () => setOpenInfoDrawer(false)}
            >
                <g.Col>
                    <g.RowStart style={{cursor: "pointer"}} onClick={() => setOpenInfoDrawer(false)}>
                        <g.ColCenter>
                            <ArrowBackIosIcon/>
                        </g.ColCenter>

                        <sg.Title margin="8px 6px">SIA / SIH</sg.Title>
                    </g.RowStart>

                    <g.Col style={{ width: "90vw"}}>
                        <sg.CardBase>
                            <g.RowBetween>
                                <sg.CardTitle>
                                    SIA
                                    <HealthAndSafetyIcon color="error" style={{"margin": s.ICON_MARGIN}}/>
                                </sg.CardTitle>

                                <g.Col>
                                    {defineSiaIcon()}
                                </g.Col>
                            </g.RowBetween>
                        </sg.CardBase>
                        
                        <st.Table>
                            <TableContainer sx={{ maxHeight: "45vh" }} component={Paper}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Und. Saúde</b></TableCell>
                                            <TableCell><b>Qtd. aprovado</b></TableCell>
                                            <TableCell><b>Val. aprovado</b></TableCell>
                                            <TableCell><b>Val. apresentado</b></TableCell>
                                            <TableCell><b>Qtd. Glosado</b></TableCell>
                                            <TableCell><b>Val. Glosado</b></TableCell>
                                            <TableCell><b>Tip. Prestador</b></TableCell>
                                            <TableCell><b>Tip. Complexidade</b></TableCell>
                                            <TableCell><b>PPI Classificação</b></TableCell>
                                            <TableCell><b>Mun. Residência</b></TableCell>
                                            <TableCell><b>Mun. Cobrança</b></TableCell>
                                            <TableCell><b>Dat. Cobrança</b></TableCell>
                                            <TableCell><b>Dat. Atendimento</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                
                                    <TableBody>                    
                                        {details?.sia && details?.sia.map((row: SIADetail) => (
                                        <StyledTableRow key={row.id}>
                                            <TableCell>{valOrNull(row.und_saude)}</TableCell>
                                            <TableCell>{valOrNull(row.qtd_aprovada)}</TableCell>
                                            <TableCell>{valOrNull(row.val_aprovado, true)}</TableCell>
                                            <TableCell>{valOrNull(row.val_apresentado, true)}</TableCell>
                                            <TableCell>{valOrNull(row.qtd_glosada)}</TableCell>
                                            <TableCell>{valOrNull(row.val_glosado, true)}</TableCell>
                                            <TableCell>{valOrNull(row.tip_prestador)}</TableCell>
                                            <TableCell>{valOrNull(row.tip_complexidade)}</TableCell>
                                            <TableCell>{valOrNull(row.ppi_classificacao)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_mun_residencia)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_mun_atendimento)}</TableCell>
                                            <TableCell>{valOrNull(dtFormatted(row.dat_cobranca))}</TableCell>
                                            <TableCell>{valOrNull(dtFormatted(row.dat_atendimento))}</TableCell>
                                        </StyledTableRow >
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </st.Table>
                    </g.Col>

                    <g.Col style={{ width: "90vw"}}>
                        <sg.CardBase>
                            <g.RowBetween>
                                <sg.CardTitle>
                                    SIH
                                    <GiteIcon color="warning" style={{"margin": s.ICON_MARGIN}}/>
                                </sg.CardTitle>
                            </g.RowBetween>
                        </sg.CardBase>
                        
                        <st.Table>
                            <TableContainer sx={{ maxHeight: "45vh"}} component={Paper}>
                                <Table stickyHeader aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Cod. CNES</b></TableCell>
                                            <TableCell><b>Nom. Hospital</b></TableCell>
                                            <TableCell><b>Num. AIH</b></TableCell>
                                            <TableCell><b>Tip. UTI</b></TableCell>
                                            <TableCell><b>Dat. Internação</b></TableCell>
                                            <TableCell><b>Dia Internação</b></TableCell>
                                            <TableCell><b>Mês Internação</b></TableCell>
                                            <TableCell><b>Dia Saída</b></TableCell>
                                            <TableCell><b>Mês Saída</b></TableCell>
                                            <TableCell><b>Dat. Competência</b></TableCell>
                                            <TableCell><b>Tip. Complexidade</b></TableCell>
                                            <TableCell><b>Nom. Especialidade</b></TableCell>
                                            <TableCell><b>PPI. Classificação</b></TableCell>
                                            <TableCell><b>Mun. Ocorrência</b></TableCell>
                                            <TableCell><b>Mun. Residência</b></TableCell>
                                            <TableCell><b>Proc. Realizado</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                
                                    <TableBody>                    
                                        {details?.sih && details?.sih.map((row: SIHDetail) => (
                                        <StyledTableRow key={row.id}>
                                            <TableCell>{valOrNull(row.cod_cnes_hospital)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_hospital)}</TableCell>
                                            <TableCell>{valOrNull(row.num_aih)}</TableCell>
                                            <TableCell>{valOrNull(row.tip_uti)}</TableCell>
                                            <TableCell>{valOrNull(row.dat_internacao)}</TableCell>
                                            <TableCell>{valOrNull(row.dia_internacao)}</TableCell>
                                            <TableCell>{valOrNull(row.mes_internacao)}</TableCell>
                                            <TableCell>{valOrNull(row.dia_saida)}</TableCell>
                                            <TableCell>{valOrNull(row.mes_saida)}</TableCell>
                                            <TableCell>{valOrNull(row.dat_competencia)}</TableCell>
                                            <TableCell>{valOrNull(row.tip_complexidade)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_especialidade)}</TableCell>
                                            <TableCell>{valOrNull(row.ppi_classificacao)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_mun_ocorrencia)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_mun_residencia)}</TableCell>
                                            <TableCell>{valOrNull(row.nom_procedimento_realizado)}</TableCell>
                                        </StyledTableRow >
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </st.Table>
                    </g.Col>
               </g.Col>
            </Drawer>
        </s.Container>
    )
}


export default MonitorinDetails


import styled from 'styled-components';


export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 8px;
    box-shadow: 4px 4px 4px lightgray;
    margin: 20px 0 24px 0;
    padding: 12px;
`

export const ContainerInputSearch = styled.div`
    display: flex;
    margin: 0px 0px 12px 0;
`
import { API_URL } from "../../../settings";
import * as request from  '../../../api/request';
import * as toastr from '../../../toastr';

interface ResponseData {
  access_token: string;
  has_produceds_access: number;
}

export const apiLogin = (username: string, password: string, redirect: () => void) => {

  const payload = new FormData();
    payload.append('username', username);
    payload.append('password', password);

    const onSuccess = (data: ResponseData) => {
      sessionStorage.setItem("accessToken", data.access_token)
      sessionStorage.setItem("hasProducedsAccess", data.has_produceds_access.toString())
      redirect()
    }

    const onError = (resp: any) => {
      if (resp.status === 401)
        toastr.error('Credenciais inválidas', 2500); 
    }

    request.post(`${API_URL}/login`, payload, onSuccess, onError, {})

}


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Search from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import WalletIcon from '@mui/icons-material/Wallet';
import { CircularProgress } from '@mui/material';

import * as s from './filter.style';
import * as sf from '../../monitoring/components/Filters.style';

import * as g from '../../../global.style';

import CardValue from '../../../components/CardValue';
import React, { useEffect, useState } from 'react';
import { AutocompleteValue, getMunicipiosAutocomplete } from '../../../common/getMunicipiosAutocomplete';
import { getChartsValues, getTetoMAC } from '../api';
import { chartsState, FilterMunicipality, filterMunicipalityState, isPercentMacState, macState } from '../state';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ChartsValues } from '../interfaces';
import * as toastr from '../../../toastr';
import { MacData } from '../../monitoring/interfaces';

type Nullable<T> = T | null | undefined;


const ChartFilters = () => {
    const [macValue, setMacState] = useRecoilState(macState)

    let [municipiosAutocomplete, setMunicipiosAutocomplete] = useState<AutocompleteValue[]>([] as AutocompleteValue[])
    
    let [filterMunicipality, setFilterMunicipality] = useRecoilState<FilterMunicipality>(filterMunicipalityState)
        
    let [checked, setChecked] = useState<boolean>(false);

    const setChartsValues = useSetRecoilState(chartsState)
    const setIsPercentMAC = useSetRecoilState(isPercentMacState)

    const [timer, setTimer] = useState<any>(null)
    const [showLoader, setShowLoader] = useState<boolean>(false)


    useEffect(() => {
        if (!macValue.teto_mac){
            setChecked(false)
            setIsPercentMAC(false)
        }
    }, [macValue.teto_mac, setIsPercentMAC])

    const onInputGetMunicipiosAutocomplete = (event: React.ChangeEvent<any>, newInputValue: any, reason: string) => {
        clearTimeout(timer)

        const newTimer = setTimeout(() => {
            if (event?.target.value) {
                getMunicipiosAutocomplete(event.target.value, (data: AutocompleteValue[]) => setMunicipiosAutocomplete(data))
            }
        }, 500)

        setTimer(newTimer)   

    }
    
    const selectMunicipio = (event: React.ChangeEvent<any>, value: Nullable<AutocompleteValue>) => {
        setFilterMunicipality({
            code: value?.value,
            label: value?.label   

        })     
    }

    const onClickSearchMunicipio = (event: React.MouseEvent<HTMLElement>) => {
        if (filterMunicipality.code === undefined || filterMunicipality.code === null || filterMunicipality.code === ""){
            toastr.warning("Por favor, selecione um município...")
            return
        }
        
        setShowLoader(true)

        getChartsValues(
            filterMunicipality.code,
            (data: ChartsValues) => {
                setShowLoader(false)
                setChartsValues(data)
            },
            (error: any) => setShowLoader(false)
        )
        getTetoMAC(filterMunicipality.code, (data: MacData) => setMacState(data), false)
    }

    const onChangeSwitchToMacPercent = (event: any) => {
        if (filterMunicipality.code){
            setChecked(!checked)
            setIsPercentMAC(!checked)

        } else {
            toastr.warning("Selecione um município")
        }
    }

    const formattedFloat = (value?: number | string) => {
        if (value === undefined || value === null)
            return "R$ ---"
        return `R$ ${value}`
    }

    return (
        <s.Container>
            <g.ColBetween>
                <g.Title margin='0 0 12px 0'>
                    {macValue.nom_mun}
                </g.Title>

                <sf.CardValueV2 title="SISMAC" icon={<WalletIcon/>} color='orange'>
                    <sf.CardValueRow title="S/ incentivos: " value={formattedFloat(macValue?.sem_incentivos)} />
                    <sf.CardValueRow title="Incentivos: " value={formattedFloat(macValue?.incentivos)} />
                    <sf.CardValueRow title="Teto mac: " value={formattedFloat(macValue?.teto_mac)} />
                </sf.CardValueV2>
            </g.ColBetween>

            <g.ColCenter>
                <s.ContainerInputSearch>
                    <Autocomplete
                        disablePortal
                        size='small'
                        onChange={selectMunicipio}
                        value={filterMunicipality.code as any}
                        id="autocompleteChart"
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            );
                            }
                        }
                        onInputChange={onInputGetMunicipiosAutocomplete}
                        options={municipiosAutocomplete}
                        isOptionEqualToValue={
                            (option: any, value: any) => {                                
                                if (typeof value === "string") {
                                    return option.value === value
                                }

                                if (value) {
                                    return option.value === value.value
                                }

                                return false
                            }                            
                        }
                        sx={{ maxWidth: 500, minWidth: 200, background: "#f1f1f1", margin: '0 4px 0 0'}}
                        renderInput={
                            (params) => {
                                // TODO: improve this using attributes from Autocomplete field
                                // This condition is use for show label after move for other page and back again for monitoring page
                               
                                if (params.inputProps.value) {
                                    // debugger
                                    let parsed = parseInt(params.inputProps.value as string)
                                    
                                    if (!isNaN(parsed)) {
                                        params.inputProps.value = filterMunicipality.label
                                    }
                                }

                                return <TextField {...params} label="Municípios" />
                            }
                        }
                    />

                    <Button variant="contained" onClick={onClickSearchMunicipio}>
                        <Search/>
                    </Button>
                </s.ContainerInputSearch>

                <g.RowBetween>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={macValue.teto_mac === undefined || macValue.teto_mac === null}
                                checked={checked}
                                onChange={onChangeSwitchToMacPercent}
                            />
                        }
                        label="Percentual Teto MAC"
                    />

                    <CircularProgress color="primary" style={{visibility: showLoader? "visible": "hidden"}}/>
                </g.RowBetween>

            </g.ColCenter>

        </s.Container>
    )
}

export default ChartFilters;
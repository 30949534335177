import styled from "styled-components";
import CloseIconMaterial from '@mui/icons-material/Close';


export const CardBase = styled.div<{flexGrow?: number}>`
    background-color: white;
    border-radius: 8px;
    box-shadow: 4px 4px 4px darkgrey;
    margin-bottom: 12px;
    overflow-y: auto;
    flex-grow: ${p => p.flexGrow? p.flexGrow : 0};
`;


export const CardTitle = styled.h3`
    padding: 12px;
    margin: auto auto auto 0;
    text-align: center;
    font-size: 18pt;
    color: #555555;
    display: flex;
`

export const LiText = styled.span<{fontSize?: string}>`
    font-size: ${p => p.fontSize? p.fontSize : "12pt"};
`

export const LiTitle = styled.b<{fontSize?: string}>`
    display: block;
    margin-bottom: 6px;
    font-size: ${p => p.fontSize || "10pt"};
    ::after {
        content: ":";
    }
`

export const Li = styled.li`
    max-width: 30vw;
    margin-bottom: 16px;
`

export const Margin = styled.div<{m?: string}>`
    margin: ${p => p.m || "auto"};
`

export const CloseIcon = styled(CloseIconMaterial)`
    border: 1px solid salmon;
    border-radius: 4px;
    color: white;
    box-shadow: 2px 2px 2px grey;
    background: salmon;
    cursor: pointer;
`

export const Title = styled.h1<{margin?: string}>`
    margin: ${p => p.margin || "auto"};
    color: #555555;
`
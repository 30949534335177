import { Subtitle, SubtitleValue} from '../../../components/CardValue';
import styled from 'styled-components';
import * as g from '../../../global.style';


export const Container = styled.div`
    display: flex;
    padding: 8px;
    justify-content: space-between;
    margin: 20px 0 12px 0;
    background-color: white;
    border-radius: 8px;
    box-shadow: 4px 4px 4px lightgray;
    flex-wrap: wrap;

    @media (max-width: 835px) {
        justify-content: space-around;
    }
`;


interface CardValueV2Props {
    title: string
    children?: any
    icon?: any
    color?: string
    margin?: string
}


const CardValueContainer = styled(g.Col)<{color?: string, margin?: string}>`
    border: 1px solid lightgrey;
    box-shadow: 4px 4px 4px lightgray;
    border-radius: 8px;
    padding: 8px;
    border-bottom: 2px solid ${p => p.color || "orange"};
    border-radius: 0px 12px;
    margin: ${p => p.margin || "0"};;
`

const IconColor = styled.div<{ color?: string, bg?: string }>`
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${p => (p.color ? p.color : g.primaryColor)};
    padding: 0px 6px;
    background-color: ${p => (p.bg ? p.bg : 'rgba(25, 118, 210, .2)')};
`

interface RowInfoProps {
    title: string
    value: string
}

export const CardValueRow = (props: RowInfoProps) => {
    return (
        <g.RowStart>
            <Subtitle style={{margin: "auto 4px auto 0"}}>{props.title}</Subtitle>
            <SubtitleValue style={{margin: "auto 0", fontSize: "14pt"}}>{props.value}</SubtitleValue>
        </g.RowStart>
    )
}


export const CardValueV2 = (props: CardValueV2Props) => {
    return (
        <CardValueContainer margin={props.margin} color={props.color}>
            <g.RowStart style={{margin: "0 0 6px 0"}}>
                <IconColor bg={props.color} color='white'>
                    {props.icon}
                </IconColor>

                <g.ColCenter>
                    <Subtitle>{props.title}</Subtitle>
                </g.ColCenter>
            </g.RowStart>

            { props.children }
        </CardValueContainer>
    )
}

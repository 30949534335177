import styled from 'styled-components';

export const primaryColor = '#1976d2';


export const Flex = styled.div`
    display: flex;
`

export const RowBetween = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
`

export const RowStart = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const RowCenter = styled.div<{padding?: string, margin?: string, flexGrow?: number}>`
    padding: ${p => p.padding ? p.padding : "0"};
    margin: ${p => p.margin ? p.margin : "0"};
    flex-grow: ${p => p.flexGrow ? p.flexGrow : 0};
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

export const RowEnd = styled.div<{padding?: string, margin?: string, flexGrow?: number}>`
    padding: ${p => p.padding ? p.padding : "0"};
    margin: ${p => p.margin ? p.margin : "0"};
    flex-grow: ${p => p.flexGrow ? p.flexGrow : 0};
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
`


export const Col = styled.div<{padding?: string, flexGrow?: number}>`
    padding: ${p => p.padding ? p.padding : "8px"};
    flex-grow: ${p => p.flexGrow ? p.flexGrow : 0};
    display: flex;
    flex-direction: column;
`;

export const ColCenter = styled.div<{padding?: string}>`
    padding: ${p => p.padding ? p.padding : "8px"};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const ColBetween = styled.div<{padding?: string}>`
    padding: ${p => p.padding ? p.padding : "8px"};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ColEvenly = styled.div<{padding?: string, flexGrow?: number}>`
    padding: ${p => p.padding ? p.padding : "8px"};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: ${p => p.flexGrow ? p.flexGrow : 0};
`

export const Title = styled.span<{margin?: string}>`
    font-size: 24pt;
    color: #565656;
    font-weight: bolder;
    margin: ${p => p.margin ? p.margin : "0"};
`

export const Margin = styled.div<{m: string}>`
    margin: ${ p => p.m };
`
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: wrap;
    width: 100%;
    height: 100%;
`;


export const Content = styled.div`
    padding: 0 12px 12px 12px;
`;

export const SegmentChartsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    height: 70vh;
    flex-wrap: wrap;
    flex-grow: 1;
    
    @media screen and (max-width: 695px) {
        justify-content: center;
    }
`;

export const TotalizerChartsContainer = styled.div<{margin?: string}>`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 70vh;
    overflow-y: auto;
    flex-grow: .3;
    margin: ${p => p.margin ? p.margin : '0px'};
`

export const ContainerBar = styled.div`
    flex-grow: .2;
`;

export const Card = styled.div<{margin?: string}>`
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 4px 4px 4px lightgray;
    flex-grow: .4;
    margin: ${p => p.margin ? p.margin : '0'};
    padding: 8px;
`;
import { MonitoringData, MonitoringFilters, MacData} from "./interfaces";
import { atom } from "recoil";
import { GeoData } from './mapInterfaces';


export const monitoringState = atom({
    key: 'monitoringState',
    default: {} as MonitoringData, 
});

export const mapMonitoringState = atom({
  key: 'mapMonitoringState',
  default: [] as GeoData[], 
});

export const filtersState = atom({
  key: 'filterState',
  default: {
    page: 1,
    codMunicipio: "",
    labelMunicipio: "",
    codProceeding: "",
    labelProceeding: "",
    pactType: "",
    segment: "",
    produceds: "",
    sigtap: ""
  } as MonitoringFilters, 
});

export const macState = atom({
  key: 'macStateMonitoring',
  default: {
    nom_mun: "Município",
    sem_incentivos: "0",
    incentivos: "0",
    teto_mac: "0",
  } as MacData
});

export const selectedPagePaginationState = atom({
  key: 'selectedPagePaginationMonitoring',
  default: 1, 
});
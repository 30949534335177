import { useState } from 'react';
import * as s from './style'
import { apiLogin } from './api/login';
import { useNavigate } from 'react-router-dom';
import * as g from '../../global.style';


const LoginPage = () => {
    let [username, setUsername] = useState<string>("")
    let [password, setPassword] = useState<string>("")

    let navigate = useNavigate()
 
    const redirect = () => {
        navigate("/monitoring")
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        apiLogin(username, password, redirect)
    }

    return (
        <s.Container>
            <s.LeftContainer>
                <s.InnerContainer>
                    <g.ColEvenly flexGrow={1}>
                        <s.Img src={"/salus8.png"} />

                        <s.PrimaryText>
                            Digite suas credenciais para acessar a plataforma
                        </s.PrimaryText>

                        <s.SecondaryText>
                            Para se cadastrar entre em contato com um de nossos administradores.
                        </s.SecondaryText>

                        <form onSubmit={handleSubmit}>
                            <g.ColEvenly>
                                <s.InputLabel>Usuário</s.InputLabel>
                                <s.Input
                                    onChange={
                                        (event) => setUsername(event.target.value)
                                    }
                                    placeholder="Digite aqui"
                                    required={true}
                                />
                                
                                <s.InputLabel>Senha</s.InputLabel>
                                <s.Input
                                    type={"password"}
                                    onChange={
                                        (event) => setPassword(event.target.value)
                                    }
                                    placeholder="Digite aqui"
                                    required={true}
                                />

                                <s.Button type='submit'>
                                        <g.RowEnd>
                                            <g.ColCenter>Entrar</g.ColCenter>

                                            <g.RowEnd flexGrow={.45}>
                                                <g.ColCenter>
                                                    <s.BtnIcon/>
                                                </g.ColCenter>
                                            </g.RowEnd>
                                        </g.RowEnd>
                                </s.Button>
                            </g.ColEvenly>
                        </form>
                        
                    </g.ColEvenly>
                </s.InnerContainer>
            </s.LeftContainer>

            <s.ImgContainer>

            </s.ImgContainer>
        </s.Container>
    )
}

export default LoginPage;
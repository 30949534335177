import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { COLORS } from '../common';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

interface Props {
    name: string;
    AACA: number;
    AMCR: number;
    AMCA: number;
    HACA: number;
    HMCR: number;
    HMCA: number;
    isPercentMAC: boolean;
}

export function HBar(props: Props) {
  const labels = ['HACA', 'AACA', 'HMCA', 'HMCA', 'HMCA', 'HMCA'];

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        // borderWidth: 5,
      },
    },
    layout: {
      padding: {
        right: 90
      }
    },
    responsive: true,
    borderRadius: 100,
    barPercentage: 0.4,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: props.name,
      },
      datalabels: {
          display: true,
          font: {
            size: 14,
            weight: "bold" as any
          },
          color: [COLORS["AACA"], COLORS["AMCR"], COLORS["AMCA"], COLORS["HACA"], COLORS["HMCR"], COLORS["HMCA"]],
          formatter: (val: number) => props.isPercentMAC ? `% ${val}` : `R$ ${val}`,
          anchor: "end" as const,
          align: "end" as const
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
            font: {
                size: 10,
            }
        }
      }
    }
  };
  
  let data = {
    labels,
    datasets: [
      {
        label: props.name,
        data: [props.AACA, props.AMCR, props.AMCA, props.HACA, props.HMCR, props.HMCA],
        backgroundColor: [COLORS["AACA"], COLORS["AMCR"], COLORS["AMCA"], COLORS["HACA"], COLORS["HMCR"], COLORS["HMCA"]],
      }
    ],
  };

  return <Bar options={options} data={data}/>;
}
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const valOrNull = (val?: string | number | any, money?: boolean) => {
    if (val === null || val === undefined) {
        return <HorizontalRuleIcon/>
    }

    if (money) {
        return `R$ ${val}`
    }
    return val
}

export const dtFormatted = (dtStr?: string | any) => {
    if (!dtStr)
        return null
    
    let dt = new Date(dtStr)
    return `${dt.getDay()}/${dt.getMonth()}/${dt.getFullYear()}`
}


export const siaIcon = (valPactuado?: number, valAprovado?: number) => {
    if (valPactuado === null || valPactuado === undefined)
        return <HorizontalRuleIcon/>
    
    if (valAprovado === null || valAprovado === undefined)
        return <HorizontalRuleIcon/>
    
    if (valAprovado > valPactuado)
        return <WarningIcon style={{'color': "red"}}/>
    return <CheckCircleIcon style={{'color': "green"}} />
}

import * as s from './Table.style';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import PublicIcon from '@mui/icons-material/Public';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { MonitoringData, MonitoringFilters, MonitoringRow } from '../interfaces';
import { useRecoilState } from "recoil";
import { monitoringState, filtersState, selectedPagePaginationState } from '../state';
import { getMonitoring } from '../api';
import * as toastr from '../../../toastr';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import GradingIcon from '@mui/icons-material/Grading';
import { siaIcon, valOrNull } from '../../../helpers/formatters';


export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

interface Props {
    openMap: () => void;
    openSummary: () => void;
}

export const MonitoringTable = (props: Props) => {
    const [filtersValue, setFiltersState] = useRecoilState(filtersState)
    const [monitoring, setMonitoring] = useRecoilState(monitoringState)
    const [selectedPage, setSelectedPage] = useRecoilState(selectedPagePaginationState)

    const navigate = useNavigate()

    const Iconize = (val?: boolean) => {
        if (val === undefined || val === null) {
            return <HorizontalRuleIcon/>
        }

        return val ? <CheckCircleIcon style={{'color': "green"}} /> : <CancelIcon style={{'color': "red"}}/>
    }

    const openSummaryPage = () => {
        if (!filtersValue.codMunicipio){
            toastr.warning("Por favor, selecione um município!")
            return
        }

        props.openSummary()
    }

    const changePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setSelectedPage(value)
        
        if (!filtersValue.codMunicipio){
            toastr.warning("Por favor, selecione um município")
            return
        }
        
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{page: value}}
        })

        getMonitoring(
            (data: MonitoringData) => setMonitoring(data),
            undefined,
            filtersValue.codMunicipio,
            filtersValue.codProceeding,
            filtersValue.pactType,
            filtersValue.segment,
            filtersValue.produceds,
            filtersValue.sigtap,
            value,
            
        )
    };

    return (
        <s.Container>
            <s.PaginationDiv>
                <s.DivBtnDownload>
                    {/* TODO: To Implements download file */}
                    {/*
                    <Button variant="contained" color="secondary">
                        <FileDownloadIcon></FileDownloadIcon>
                    </Button>
                    &nbsp; */}
                    <Button variant="contained" color="success" onClick={props.openMap}>
                        <PublicIcon></PublicIcon>
                    </Button>

                    {/* <Button variant="contained" sx={{margin: "0 4px"}} color="success" onClick={openSummaryPage}>
                        <GradingIcon/>
                    </Button> */}
                </s.DivBtnDownload>
                
                <Pagination
                    defaultPage={selectedPage}
                    count={monitoring?.pagination?.pages || 1}
                    shape="rounded" color="primary"
                    onChange={changePage}
                />
            </s.PaginationDiv>

            <s.Table style={{maxHeight: "50vh"}}>
                <TableContainer component={Paper}>
                    <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Detalhes</b></TableCell>
                                <TableCell><b>Código</b></TableCell>
                                <TableCell><b>Procedimento</b></TableCell>
                                <TableCell><b>Flg. Sigtap</b></TableCell>
                                <TableCell><b>Nom. Segmento</b></TableCell>
                                <TableCell><b>Mun. Encaminhador</b></TableCell>
                                <TableCell><b>Mun. Executor</b></TableCell>
                                <TableCell><b>Qtd. física</b></TableCell>
                                <TableCell><b>Val. Unitário</b></TableCell>
                                <TableCell><b>Val. Financeiro</b></TableCell>
                                <TableCell><b>Qtd. aprovado</b></TableCell>
                                <TableCell><b>Flg. SIA</b></TableCell>
                                <TableCell><b>Val. total aprovado</b></TableCell>
                                <TableCell><b>Val. total apresentado</b></TableCell>
                                <TableCell><b>Qtd. glosado</b></TableCell>
                            </TableRow>
                        </TableHead>
                    
                        <TableBody>                    
                            {monitoring?.data && monitoring?.data.map((row: MonitoringRow) => (
                            <StyledTableRow key={row.id}>
                                <TableCell>
                                    <IconButton
                                        onClick={() => navigate("/monitoring/details", {state: row})}
                                        color="primary" aria-label="Info" title='Detalhes' component="label">
                                        <InfoIcon/>
                                    </IconButton>
                                </TableCell>

                                <TableCell>{valOrNull(row.cod_proc)}</TableCell>
                                <TableCell>{valOrNull(row.nom_proc)}</TableCell>
                                <TableCell>{Iconize(row.flg_sigtap_proc)}</TableCell>
                                <TableCell>{valOrNull(row.nom_segmento)}</TableCell>
                                <TableCell>{valOrNull(row.nom_mun_encaminhador)}</TableCell>
                                <TableCell>{valOrNull(row.nom_mun_executor)}</TableCell>
                                <TableCell>{valOrNull(row.qtd_fisica)}</TableCell>
                                <TableCell>{valOrNull(row.val_unitario, true)}</TableCell>
                                <TableCell>{valOrNull(row.fin_encaminhador, true)}</TableCell>
                                <TableCell>{row.qtd_aprovada === -1 ? '*****' : valOrNull(row.qtd_aprovada)}</TableCell>
                                <TableCell>{row.val_total_aprovado === -1 ? '*****': siaIcon(row.fin_encaminhador, row.val_total_aprovado)}</TableCell>
                                <TableCell>{row.val_total_aprovado === -1 ? '*****' : valOrNull(row.val_total_aprovado, true)}</TableCell>
                                <TableCell>{row.val_total_apresentado === -1 ? '*****' : valOrNull(row.val_total_apresentado, true)}</TableCell>
                                <TableCell>{row.qtd_glosada === -1 ? '*****' : valOrNull(row.qtd_glosada)}</TableCell>
                            </StyledTableRow >
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </s.Table>
        </s.Container>
    )
}
import styled from 'styled-components';
import * as st from './totalizers.style';

export interface TitleIconizedProps {
    icon: any;
    iconBg: string;
    title: string;
    titleColor: string;
    size?: string; 
    iconSize?: string;
}


const Title = styled.span<{color: string, size?: string}>`
    font-size: ${p => (p.size ? p.size : '14pt')};
    margin: auto auto auto 0;
    font-weight: bolder;
    color: ${p => (p.color ? p.color : 'green')};
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 8px 0 0 0;
`;



const TitleIconized = (props: TitleIconizedProps) => {
    return (
        <TitleContainer>
            <st.IconCenter color={props.titleColor} bg={props.iconBg} style={{margin: 'auto 8px 0 auto'}}>
                <props.icon fontSize={props.iconSize ? props.iconSize : '14pt'}></props.icon>
            </st.IconCenter>
            
            <Title color={props.titleColor} size={props.size}>
                {props.title}
            </Title>
        </TitleContainer>
    )
}

export default TitleIconized;
import { API_URL } from '../settings';
import * as request from '../api/request';

export interface AutocompleteValue {
    label: string;
    value: string;
}

export const getMunicipiosAutocomplete = (partOfName: string, onSuccess: (data: AutocompleteValue[]) => void) => {
    let url = `${API_URL}/municipality/autocomplete?name=${partOfName}`;
    request.get(url, onSuccess)
}
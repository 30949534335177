import { toast } from 'react-toastify';


const config = (autoClose?: number) => {
    return {
        position: "top-right",
        autoClose: autoClose ? autoClose : 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    } as any
}


export const error = (message: string, autoClose?: number) => {
    toast.error(message, config(autoClose));
}

export const warning = (message: string) => {
    toast.warning(message, config());
}

export const info = (message: string, autoClose?: number) => {
    toast.info(message, config(autoClose));
}
import { MapContainer, TileLayer, Popup, GeoJSON } from 'react-leaflet'
import * as s from './Map.style';
import { useRecoilValue } from "recoil";
import { macState, mapMonitoringState } from '../state';
import { FeatureProps, GeoData, MapProps } from '../mapInterfaces';



interface IPopup {
    searchedMun?: string;
    text: string;
    value: number;
    br?: boolean
}


const PopupLi = (props: IPopup) => {
    return (
        <>
            <span>{props.searchedMun && <b>{props.searchedMun}</b>}{props.text}:&nbsp;</span>
            <b>{props.value}</b> {props.value === 1 ? 'proc': 'procs'}.
            {props.br && <br/>}
        </>
    )
}


const Feature = (props: FeatureProps) => {
    let forwardeds;
    let executeds;
    let selfPact;
    
    
    if (props.data.properties.forwardeds){
        forwardeds = (
            <PopupLi
                searchedMun={props.nomMun}
                text=" encaminhou"
                value={props.data.properties.forwardeds}
                br={true}
            />
        )
    }

    if (props.data.properties.executeds){
        executeds = (
            <PopupLi
                searchedMun={props.nomMun}
                text=" executou"
                value={props.data.properties.executeds}
                br={true}
            />
        )
    }

    if (props.data.properties.selfPact){
        selfPact = (
            <PopupLi
                text="Auto Pactuado"
                value={props.data.properties.selfPact}
            />
        )
    }

    return (
        <GeoJSON
            key={props.data.properties.id} data={props.data.geometry as any}
            pathOptions={props.data.properties.style}
            eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup()
            }}
        >
            <Popup>
                <div style={{"display": "flex"}}>
                    <b style={{"margin": "auto"}}>{props.data.properties.title}</b>
                </div>

                <br/>

                {forwardeds && forwardeds}
                {executeds && executeds}
                {selfPact && selfPact}
            </Popup>

        </GeoJSON>
    )
}


const Map = (props: MapProps) => {
    let mapMonitoring = useRecoilValue(mapMonitoringState)
    let macValue = useRecoilValue(macState)
    
    return (
        <s.Container>
            <s.CloseButton onClick={props.closeMap}>X</s.CloseButton>

            <MapContainer center={[-15.7801, -47.1917]} zoom={5} style={{height: '90vh', width: '100%'}}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {
                    mapMonitoring && mapMonitoring.map(
                        (data: GeoData) => <Feature key={data.properties.id} data={data} nomMun={macValue.nom_mun}/>
                    )
                }
            </MapContainer>
        </s.Container>
    )
}

export default Map;
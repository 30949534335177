import { HBar } from "./hbar";
import * as s from './horizontalBars.style';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';

import TitleIconized, { TitleIconizedProps } from './titleIconized';
import { MunValues } from "../interfaces";
import { useEffect, useRef, useState } from "react";
import { unnacent } from "../../../helpers/unnacent";


interface Props {
    title: TitleIconizedProps;
    data: MunValues[];
    margin?: string;
    ribbonColor?: string;
    isPercentMAC: boolean;
}


const HorizontalBars = (props: Props) => {
    let [name, setName] = useState<string>("")

    let ref = useRef<any>()

    useEffect(() => {
        setName("")
    }, [props.data])

    const compare = (s1: string, s2: string) => {
        if (s2)
            return unnacent(s1).toLowerCase().startsWith(s2)
        return true
    }

    let HandleClickFilter = () => {
        setName(ref.current?.value)
    }

    return (
        <s.Container margin={props.margin}>
            <s.Ribbon color={props.ribbonColor ? props.ribbonColor : "green"}></s.Ribbon>
            
            <s.Content>
                <TitleIconized
                    title={props.title.title}
                    titleColor={props.title.titleColor}
                    icon={props.title.icon}
                    iconBg={props.title.iconBg}
                />
                <s.SearchContainer> 
                    <TextField
                        id="outlined-basic"
                        label="Município..."
                        variant="outlined"
                        size="small"
                        inputRef={ref}
                    />
                    <Button
                        onClick={HandleClickFilter}
                        variant="contained">
                        <SearchIcon/>
                    </Button>
                </s.SearchContainer>

                <s.Bars>
                    {
                        props.data.filter(
                            (item: MunValues) => compare(item.name, name)).map((item: MunValues) => {
                                return (
                                    <s.CardBar key={item.name + Math.random().toString()}>
                                        {/* TODO: adjust key */}
                                        <HBar
                                            name={item.name}
                                            AACA={item.values.AACA}
                                            AMCR={item.values.AMCR}
                                            AMCA={item.values.AMCA}
                                            HACA={item.values.HACA}
                                            HMCR={item.values.HMCR}
                                            HMCA={item.values.HMCA}
                                            isPercentMAC={props.isPercentMAC}
                                        />
                                    </s.CardBar>
                                )
                            }
                        )
                    }
                </s.Bars>

                
            </s.Content>
        </s.Container>
    )
}

export default HorizontalBars;
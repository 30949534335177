import { atom } from "recoil";
import { MacData } from "../monitoring/interfaces";
import { ChartsValues } from "./interfaces";


export const chartsState = atom({
    key: 'chartsState',
    default: {
        percentMac: {
            totalizers: {
                sumAutoPacts: 0,
                sumExecuteds: 0,
                sumForwarders: 0
            },
            totalBySegments: {
                executeds: {
                    AACA: 0,
                    AMCA: 0,
                    AMCR: 0,
                    HACA: 0,
                    HMCA: 0,
                    HMCR: 0
                },
                forwardeds: {
                    AACA: 0,
                    AMCA: 0,
                    AMCR: 0,
                    HACA: 0,
                    HMCA: 0,
                    HMCR: 0
                },
                autoPacts: {
                    AACA: 0,
                    AMCA: 0,
                    AMCR: 0,
                    HACA: 0,
                    HMCA: 0,
                    HMCR: 0
                }
            },
            totalByMun: {
                executeds: [
                    {
                        name: "",
                        values: {
                            AACA: 0,
                            AMCA: 0,
                            AMCR: 0,
                            HACA: 0,
                            HMCA: 0,
                            HMCR: 0
                        }
                    }
                ],
                forwardeds: [
                    {
                        name: "",
                        values: {
                            AACA: 0,
                            AMCA: 0,
                            AMCR: 0,
                            HACA: 0,
                            HMCA: 0,
                            HMCR: 0
                        }
                    }
                ]
            }
        },
        absolute: {
            totalizers: {
                sumAutoPacts: 0,
                sumExecuteds: 0,
                sumForwarders: 0
            },
            totalBySegments: {
                executeds: {
                    AACA: 0,
                    AMCA: 0,
                    AMCR: 0,
                    HACA: 0,
                    HMCA: 0,
                    HMCR: 0
                },
                forwardeds: {
                    AACA: 0,
                    AMCA: 0,
                    AMCR: 0,
                    HACA: 0,
                    HMCA: 0,
                    HMCR: 0
                },
                autoPacts: {
                    AACA: 0,
                    AMCA: 0,
                    AMCR: 0,
                    HACA: 0,
                    HMCA: 0,
                    HMCR: 0
                }
            },
            totalByMun: {
                executeds: [
                    {
                        name: "",
                        values: {
                            AACA: 0,
                            AMCA: 0,
                            AMCR: 0,
                            HACA: 0,
                            HMCA: 0,
                            HMCR: 0
                        }
                    }
                ],
                forwardeds: [
                    {
                        name: "",
                        values: {
                            AACA: 0,
                            AMCA: 0,
                            AMCR: 0,
                            HACA: 0,
                            HMCA: 0,
                            HMCR: 0
                        }
                    }
                ]
            }
        }
    } as ChartsValues,
});


export const isPercentMacState = atom({
    key: 'percentMac',
    default: false
})


export const macState = atom({
    key: 'macStateCharts',
    default: {
        nom_mun: "Município",
        sem_incentivos: "0",
        incentivos: "0",
        teto_mac: "0",
    } as MacData
});


export interface FilterMunicipality {
    code?: string;
    label?: string;
}


export const filterMunicipalityState = atom({
    key: 'filterMunicipalityState',
    default: {} as FilterMunicipality, 
});
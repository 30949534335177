import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PaginationDiv = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 4px 4px 4px lightgray;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        justify-content: space-around;
    }
`;

export const DivBtnDownload = styled.div`
    margin-bottom: 4px;
`

export const Table = styled.div`
    overflow-x: auto;
    border: 1px solid lightgrey;
    border-radius: 4px;
    box-shadow: 4px 4px 4px lightgray;
`;

import React, { useState } from "react";
import Filters from "./components/Filters"
import { MonitoringTable } from "./components/Table"
import Drawer from '@mui/material/Drawer';
import Map from "./components/Map";
import Navbar, { MenuItems } from '../../components/Navbar';

import * as s from './style';
import Summary from "./pages/Summary";


export const MonitoringPage = () => {
    const [openMap, setOpenMap] = useState<boolean>(false)
    const [openSummary, setOpenSummary] = useState<boolean>(false)
    

    return (
        <s.Container>
            <Navbar activedMenu={MenuItems.monitoring}/>

            <s.Content>                
                <Filters/>
                
                <MonitoringTable
                    openMap={() => setOpenMap(true)}
                    openSummary={() => setOpenSummary(true)}
                />

                <Drawer
                    variant="temporary"
                    anchor={"bottom"}
                    open={openMap}
                    onClose={() => setOpenMap(false)}>
                    <Map
                        closeMap={() => setOpenMap(false)}
                    />
                </Drawer>

                <Drawer
                    variant="temporary"
                    anchor={"bottom"}
                    open={openSummary}
                    onClose={() => setOpenSummary(false)}>

                    <Summary onClose={() => setOpenSummary(false)}/>
                </Drawer>
            </s.Content>
        </s.Container>
    )
}
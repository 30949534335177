import * as request from './request';
import { API_URL } from '../settings';
import * as toastr from '../toastr';


export const checkAPIAvailability = (redirectToLogin: () => void) => {
    const onError = (respError: any) => {
        if (respError.status === undefined){
            toastr.error('Erro de conexão com servidor!');
            redirectToLogin()
        }
    }

    request.get(API_URL, undefined, onError)
}
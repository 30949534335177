
import * as s from './style';
import * as g from '../../global.style';
import Totalizers from "./components/totalizers";
import VBar from "./components/bar";
import HorizontalBars from './components/horizontalBars';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import TitleIconized from './components/titleIconized';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChartFilters from './components/filters';
import Navbar, { MenuItems } from '../../components/Navbar';
import { useRecoilValue } from 'recoil';
import { chartsState, isPercentMacState } from './state';
import { ReportsValues } from './interfaces';


export const Charts = () => {

    let charts = useRecoilValue(chartsState)
    let isPercentMAC = useRecoilValue(isPercentMacState)

    let values: ReportsValues = charts.absolute;

    if (isPercentMAC) {
        values = charts.percentMac
    }

    return (
        <s.Container>
            <Navbar activedMenu={MenuItems.charts}/>

            <s.Content>
                <ChartFilters/>

                <Totalizers
                    executeds={charts.absolute.totalizers.sumExecuteds}
                    forwardeds={charts.absolute.totalizers.sumForwarders}
                    autoPacts={charts.absolute.totalizers.sumAutoPacts}
                    executedsPercentMAC={charts.percentMac.totalizers.sumExecuteds}
                    forwarderPercentMAC={charts.percentMac.totalizers.sumForwarders}
                    autoPactsPercentMAC={charts.percentMac.totalizers.sumAutoPacts}
                    isPercentMAC={isPercentMAC}
                />

                <g.RowBetween>
                    <s.TotalizerChartsContainer margin='6px 2px 0 2px'>
                        <s.Card>
                            <TitleIconized
                                size="12pt"
                                title="Executados"
                                titleColor="green"
                                icon={CallReceivedIcon}
                                iconBg="rgba(0, 128, 0, .2)"
                            />
                            <s.ContainerBar>
                                <VBar
                                    AACA={values.totalBySegments.executeds.AACA}
                                    AMCR={values.totalBySegments.executeds.AMCR}
                                    AMCA={values.totalBySegments.executeds.AMCA}
                                    HACA={values.totalBySegments.executeds.HACA}
                                    HMCR={values.totalBySegments.executeds.HMCR}
                                    HMCA={values.totalBySegments.executeds.HMCA}
                                    isPercentMAC={isPercentMAC}
                                />
                            </s.ContainerBar>
                        </s.Card>

                        <s.Card margin='6px 0'>
                            <TitleIconized
                                size="12pt"
                                title="Encaminhados"
                                titleColor="red"
                                icon={ArrowOutwardIcon}
                                iconBg="rgba(255, 0, 0, 0.2)"
                            />

                            <s.ContainerBar>
                                <VBar
                                    AACA={values.totalBySegments.forwardeds.AACA}
                                    AMCR={values.totalBySegments.forwardeds.AMCR}
                                    AMCA={values.totalBySegments.forwardeds.AMCA}
                                    HACA={values.totalBySegments.forwardeds.HACA}
                                    HMCR={values.totalBySegments.forwardeds.HMCR}
                                    HMCA={values.totalBySegments.forwardeds.HMCA}
                                    isPercentMAC={isPercentMAC}
                                />
                            </s.ContainerBar>
                        </s.Card>

                        <s.Card>
                            <TitleIconized
                                size="12pt"
                                title="Auto Pactuado"
                                titleColor={g.primaryColor}
                                icon={AutorenewIcon}
                                iconBg="rgba(25, 118, 210, .2)"
                            />
                            <s.ContainerBar>
                                <VBar
                                    AACA={values.totalBySegments.autoPacts.AACA}
                                    AMCR={values.totalBySegments.autoPacts.AMCR}
                                    AMCA={values.totalBySegments.autoPacts.AMCA}
                                    HACA={values.totalBySegments.autoPacts.HACA}
                                    HMCR={values.totalBySegments.autoPacts.HMCR}
                                    HMCA={values.totalBySegments.autoPacts.HMCA}
                                    isPercentMAC={isPercentMAC}
                                />
                            </s.ContainerBar>
                        </s.Card>

                    </s.TotalizerChartsContainer>
                    
                    <s.SegmentChartsContainer>
                        <HorizontalBars
                            margin='8px 0 0 0'
                            data={values.totalByMun.executeds}
                            ribbonColor="transparent"
                            title={{
                                title: "Exec. para:",
                                titleColor: "green",
                                icon: CallReceivedIcon,
                                iconBg: "rgba(0, 128, 0, .2)",
                            }}
                            isPercentMAC={isPercentMAC}
                        />
                        <HorizontalBars
                            margin='8px 0 0 0'
                            ribbonColor="transparent"
                            data={values.totalByMun.forwardeds}
                            title={{
                                title: "Encam. para:",
                                titleColor: "red",
                                icon: ArrowOutwardIcon,
                                iconBg: "rgba(255, 0, 0, 0.2)",
                            }}
                            isPercentMAC={isPercentMAC}
                        />
                    </s.SegmentChartsContainer>
                    
                </g.RowBetween>
            </s.Content>
        </s.Container>
    )
}

import styled from 'styled-components';
import * as g from '../../global.style';
import { CardBase } from '../../styles/components';


export const ICON_MARGIN = "auto 4px";


export const Container = styled.div`
    width: 100%;
    height: 100vh;
`;


export const Card = styled(CardBase)`
    min-height: 45vh;
    max-height: 50vh;
    flex-grow: 1;
` 

export const ColContent = styled(g.Col)`
    flex-grow: .5;
    
    @media screen and (max-width: 700px) {
        flex-grow: 1;
    }
`

export const ContainerContent = styled.div`
    background-color: #f1f1f1;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
`
import { API_URL } from "../../settings";
import * as request from '../../api/request';
import { AutocompleteValue } from '../../common/getMunicipiosAutocomplete';
import { SummaryData } from "./pages/SummaryInterfaces";


interface OnSuccess {
  (data: any): void; // MonitoringData[] | GeoData[]
}

interface OnError {
  (error: any): void; // error
}


export const getProceedingsAutocomplete = (partOfName: string, onSuccess: (data: AutocompleteValue[]) => void) => {
  let url = `${API_URL}/proceedings/autocomplete?name=${partOfName}`;
  request.get(url, onSuccess)
}


const buildQs = (mun?: string, proc?: string, pactType?: string, segment?: string, produceds?: string, sigtap?: string, page?: number) => {
  let qs = ""

  if (mun) {
    qs += `mun=${mun}&`
  }
  
  if (proc) {
    qs += `proc=${proc}&`
  }

  if (pactType) {
    qs += `type=${pactType}&`
  }

  if (segment) {
    qs += `seg=${segment}&`
  }
  
  if (produceds) {
    qs += `sia=${produceds}&`
  }

  if (sigtap) {
    qs += `sigtap=${sigtap}&`
  }

  if (page) {
    qs += `page=${page}&`
  }

  return qs.slice(0, qs.length - 1)
}


export const getMonitoring = (onSuccess: OnSuccess, onError: OnError | undefined, mun: string, proc?: string, pactType?: string, segment?: string, produceds?: string, sigtap?: string, page?: number, showErrorMessage?: boolean) => {
  if (showErrorMessage === undefined){
    showErrorMessage = true
  }

  let qs = buildQs(mun, proc, pactType, segment, produceds, sigtap, page)

  request.get(`${API_URL}/monitoring?${qs}`, onSuccess, onError, null, showErrorMessage)
}

export const getGeoLayer = (onSuccess: OnSuccess, onError: OnError, mun: string, proc?: string, pactType?: string, segment?: string, produceds?: string, sigtap?: string, showErrorMessage?: boolean) => {
  
  let qs = buildQs(mun, proc, pactType, segment, produceds, sigtap)
 
  request.get(`${API_URL}/monitoring/geo?${qs}`, onSuccess, onError, null, showErrorMessage)
}


export const getDetails = (onSuccess: OnSuccess, cod_proc: string, cod_mun_forwarder: string, cod_mun_executor: string) => {
  request.get(
    `${API_URL}/monitoring/detail?cod_proc=${cod_proc}&cod_mun_forwarder=${cod_mun_forwarder}&cod_mun_executor=${cod_mun_executor}`,
    onSuccess
  )  
} 


interface onSuccessGetSummary {
  (data: SummaryData): void
}


export const getSummary = (onSuccess: onSuccessGetSummary, mun?: string, pactType?: string, segment?: string, produceds?: string) => {  
  const qs = buildQs(mun, undefined, pactType, segment, produceds)
  
  request.get(
    `${API_URL}/monitoring/summary?${qs}`, onSuccess
  )  
} 
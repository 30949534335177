import * as s from "./Summary.style"
import * as sg from "../../../styles/components";
import * as grid from "../../../global.style";
import CancelIcon from '@mui/icons-material/Cancel';
import { valOrNull } from "../../../helpers/formatters";
import { useEffect, useState } from "react";
import { SummaryData } from "./SummaryInterfaces";
import * as api from "../api";
import { useRecoilValue } from "recoil";
import { filtersState } from "../state";


interface Props {
    onClose: () => void
}


const Summary = (props: Props) => {
    
    const [data, setData] = useState<SummaryData>();
    const filtersValue = useRecoilValue(filtersState)

    useEffect(
        () => {
            api.getSummary(
                (data: SummaryData) => setData(data), filtersValue.codMunicipio, filtersValue.pactType, filtersValue.segment, filtersValue.produceds
            )
        }, []
    )

    return (
        <s.Container>
            <grid.RowBetween>
                <sg.Title margin="18px 6px">Sumarização</sg.Title>

                <sg.CloseIcon style={{margin: "auto 0"}} onClick={() => props.onClose()}/>
            </grid.RowBetween>

            <sg.CardBase>
                <sg.CardTitle>
                    Desacordo com SIGTAP

                    <CancelIcon style={{'color': "red", margin: "auto 8px"}}/>
                </sg.CardTitle>
            </sg.CardBase>

            <sg.CardBase>
                <ul>
                    <sg.Li>
                        <sg.LiTitle fontSize="12pt">Número de Procedimentos</sg.LiTitle>
                        <sg.LiText>
                            {valOrNull(data?.sigtap.desacordo.qtd_procedimentos)}
                        </sg.LiText>
                    </sg.Li>

                    <sg.Li>
                        <sg.LiTitle fontSize="12pt">Valor total</sg.LiTitle>
                        <sg.LiText>
                            {valOrNull(data?.sigtap.desacordo.val_total, true)}
                        </sg.LiText>
                    </sg.Li>
                </ul>
            </sg.CardBase>
        </s.Container>
    )
}


export default Summary;

import styled from 'styled-components';
import * as g from '../../../global.style';


export const Container = styled.div`
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
    margin: 0 0 24px 0;
`;


export const CardTotalizer = styled.div<{ margin?: string }>`
    border: 1px solid lightgray;
    background: white;
    border-radius: 8px;
    box-shadow: 4px 4px 4px lightgray;
    margin: ${p => (p.margin ? p.margin : '0')};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`

export const CardTotalizerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    flex-grow: 1;
`

export const CardTotalizerValueContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 4px 0;
    flex-grow: 1;
`

export const IconCenter = styled.div<{ color?: string, bg?: string }>`
    display: flex;
    margin: auto;
    border-radius: 24px;
    color: ${p => (p.color ? p.color : g.primaryColor)};
    padding: 8px;
    background-color: ${p => (p.bg ? p.bg : 'rgba(25, 118, 210, .2)')};
`

export const CardTotalizerTitle = styled.span<{ color?: string }>`
    margin: 0 auto 12px auto;
    color: ${p => (p.color ? p.color : g.primaryColor)};
    font-weight: bolder;
    font-size: 12pt;
    padding: 2px 4px;
`

export const CardTotalizerMainValue = styled.span`
    font-size: 14pt;
    text-align: center;
    color: #4c4c4c;
    `

export const CardTotalizerSecondaryValue = styled.span`
    font-size: 10pt;
    text-align: center;
    color: #4c4c4c;
`

export const CardTotalizerBottomRibbon = styled.div<{ color?: string }>`
    width: 100%;
    background-color: ${p => (p.color ? p.color : g.primaryColor)};
    height: 6px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

export const MiniSpan = styled.span`
    font-size: 10pt;
`
export const SpanMainValue = styled.span`
    font-size: 20pt;
    font-weight: bolder;
    color: #555555;
`
import * as st from '../pages/charts/components/totalizers.style';
import * as g from '../global.style';
import styled from 'styled-components';


const CardSubtitle = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 16px 0 0 0;
    border: 1px solid lightgrey;
    border-bottom: 2px solid orange;
    box-shadow: 4px 4px 4px lightgray;
    border-radius: 0px 12px;
    padding: 8px;
`

export const Subtitle = styled.span`
    font-size: 12pt;
    color: grey;    
`

export const SubtitleValue = styled.span`
    font-size: 16pt;
    color: grey;
    font-weight: bold;
`

interface Props {
    label: string;
    value: string | number;
    icon: any;
}

const CardValue = (props: Props) => {
    return (
        <CardSubtitle>
            <st.IconCenter color="white" bg="rgba(255, 165, 0)">
                <props.icon></props.icon>
            </st.IconCenter>

            <g.ColCenter>
                <Subtitle>{props.label}</Subtitle>
                <SubtitleValue>{props.value}</SubtitleValue>
            </g.ColCenter>
        </CardSubtitle>
    )
}

export default CardValue;
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    padding: 0 2vw 2vh 2vw;
    flex-direction: column;
`;

import * as toastr from '../toastr';

const baseHeaders = (headers?: any) =>{
  let accessToken = sessionStorage.getItem("accessToken")
  
  if (headers)
    return headers
  else
    return {"content-type": "application/json", "Authorization": `bearer ${accessToken}`}
}

export const checkErrors = async(resp: any, showErrorMessages?: boolean) => {

  let data = await resp.json()

  if (resp.status === 401 && data.detail.code === "EXPIRED_TOKEN"){
    
    if (showErrorMessages)
      toastr.warning('Sessão expirada! Encerrando...');

    setTimeout(() => {
      window.location = "/login" as any
    }, 3000)
  }

  if (resp.status === 403 && showErrorMessages){
    toastr.error(data.detail.message)
  }

  if (resp.status === 500){
    toastr.error("Erro interno do servidor")
  }
}

export const get = (url: string, onSuccess?: (data: any) => void, onError?: (resp: any) => void, headers?: any, showErrorMessages?: boolean) => {
  headers = baseHeaders(headers)

  if (showErrorMessages === undefined) {
    showErrorMessages=true
  }
  
    
  fetch(url, {headers: headers})
    .then(response => {
      if (response.ok) {
        return response.json()
      }
      return Promise.reject(response);
      
    })
    .then((data: any) => {
      if (onSuccess)
        onSuccess(data)
    
    }).catch(async(respError) => {
      await checkErrors(respError, showErrorMessages)

      if (onError)
        onError(respError)
    });
}

export const post = (url: string, payload: any, onSuccess: (data: any) => void, onError?: (resp: any) => void, headers?: any, showErrorMessages?: boolean) => {

  if (showErrorMessages === undefined) {
    showErrorMessages=true
  }

  let payloadReq;

  if (payload instanceof FormData){
    payloadReq = payload    
  }
  else{
    payloadReq = JSON.stringify(payload)
    
  }
  
  headers = baseHeaders(headers)

  fetch(url, {method: "POST", body: payloadReq, headers: headers})
  .then(response => {
    if (response.ok) {
      return response.json()
    }
    return Promise.reject(response);
    
  })
  .then((data: any) => {
    onSuccess(data)
  
  }).catch(async(resp) => {
    await checkErrors(resp, showErrorMessages)

    if (onError)
      onError(resp)
  })
  ;
}
import * as s from './Filters.style';
import * as g from '../../../global.style';
import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import WalletIcon from '@mui/icons-material/Wallet';
import { useState } from 'react';
import { Button, CircularProgress, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { MacData, MonitoringData, MonitoringFilters } from '../interfaces';
import { getMunicipiosAutocomplete, AutocompleteValue } from '../../../common/getMunicipiosAutocomplete';
import { getProceedingsAutocomplete, getMonitoring, getGeoLayer, getSummary } from '../api';
import { useSetRecoilState, useRecoilState } from "recoil";
import { GeoData } from '../mapInterfaces';
import { monitoringState, mapMonitoringState, filtersState, macState, selectedPagePaginationState } from '../state';
import { getTetoMAC } from '../../charts/api';
import * as toastr from '../../../toastr';
import { PactType, Produceds, Segments, Sigtap } from '../enums';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { SummaryData } from '../pages/SummaryInterfaces';
import HandshakeIcon from '@mui/icons-material/Handshake';


type Nullable<T> = T | null | undefined;


const Filters = () => {

    let hasProducedsAccess = parseInt(sessionStorage.getItem("hasProducedsAccess") || "0")

    const [macValue, setMacState] = useRecoilState(macState)

    const setMonitoring = useSetRecoilState(monitoringState)
    const setMapMonitoring = useSetRecoilState(mapMonitoringState)
    
    const [filtersValue, setFiltersState] = useRecoilState(filtersState)

    const [timer, setTimer] = useState<any>(null)
    const [timerProc, setTimerProc] = useState<any>(null)

    const [showLoader, setShowLoader] = useState<boolean>(false)
    const setSelectedPage = useSetRecoilState(selectedPagePaginationState)

    let [municipiosAutocomplete, setMunicipiosAutocomplete] = useState<AutocompleteValue[]>([] as AutocompleteValue[])
    let [proceedingsAutocomplete, setProceedingsAutocomplete] = useState<AutocompleteValue[]>([] as AutocompleteValue[])

    const [rerender, setRerender] = useState(false);

    const [summaryData, setSummaryData] = useState<SummaryData>()

    const clearFilters = () => {
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{
                codMunicipio: "",
                labelMunicipio: "",
                codProceeding: "",
                labelProceeding: "",
                pactType: "",
                segment: "",
                produceds: "",
                sigtap: ""
            }}
        })
        setRerender(!rerender) // force rerender for clear input values
    }

    const selectMunicipio = (event: React.ChangeEvent<any>, value: Nullable<AutocompleteValue>) => {
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{codMunicipio: value?.value, labelMunicipio: value?.label}}
        })
    }
    const selectProceeding = (event: React.ChangeEvent<any>, value: Nullable<AutocompleteValue>) => {
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{codProceeding: value?.value, labelProceeding: value?.label}}
        })
    }

    const selectSegment = (event: any) => {        
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{segment: event.target.value}}
        })
    }
    const selectPactType = (event: any) => {
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{pactType: event.target.value}}
        })
    }
    const selectProduceds = (event: any) => {
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{produceds: event.target.value}}
        })
    }
    const selectSigtap = (event: any) => {
        setFiltersState((state: MonitoringFilters | any) => {
            return {...state, ...{sigtap: event.target.value}}
        })
    }

    const filterMonitoring = () => {
        if (!filtersValue.codMunicipio){
            toastr.warning("Por favor, selecione um município!")
            return
        }

        setSelectedPage(1)
        setShowLoader(true)
        
        getMonitoring(
            (data: MonitoringData) => {
                if (data.data.length === 0){
                    toastr.warning("Nenhum registro encontrado!")
                } 
                setMonitoring(data)
            },
            (error: any) => {
                setShowLoader(false)
            },
            filtersValue.codMunicipio,
            filtersValue.codProceeding,
            filtersValue.pactType,
            filtersValue.segment,
            filtersValue.produceds,
            filtersValue.sigtap,
            filtersValue.page,
            true
        )
        
        getGeoLayer(
            (data: GeoData[]) => {
                setShowLoader(false)
                setMapMonitoring(data)
            },
            (error: any) => {
                setShowLoader(false)
            },
            filtersValue.codMunicipio,
            filtersValue.codProceeding,
            filtersValue.pactType,
            filtersValue.segment,
            filtersValue.produceds,
            filtersValue.sigtap,
            false
        )

        getTetoMAC(
            filtersValue.codMunicipio,
            (data: MacData) => setMacState(data),
            false
        )

        getSummary(
            (data: SummaryData) => setSummaryData(data),
            filtersValue.codMunicipio, filtersValue.pactType, filtersValue.segment, filtersValue.produceds
        )
    }

    const onInputGetMunicipiosAutocomplete = (event: React.ChangeEvent<any>) => {
        clearTimeout(timer)

        const newTimer = setTimeout(() => {

            if (event?.target.value) {
                getMunicipiosAutocomplete(event?.target.value, (data: AutocompleteValue[]) => {
                    setMunicipiosAutocomplete(data)
                })
            }
        }, 500)

        setTimer(newTimer)
    }

    const onInputGetProceedingsAutocomplete = (event: React.ChangeEvent<any>) => {
        clearTimeout(timerProc) 

        const newTimerProc = setTimeout(
            () => {
                getProceedingsAutocomplete(event.target.value, (data: AutocompleteValue[]) => setProceedingsAutocomplete(data))
            }, 500
        )
        
        setTimerProc(newTimerProc)
    }

    const formattedFloat = (value?: number | string) => {
        if (value === undefined || value === null)
            return "R$ ---"
        return `R$ ${value}`
    }

    return (
        <s.Container>
            <g.ColCenter padding="0 0 0 20px">
                <g.Title style={{margin: "12px 0 0 0", fontSize: "20pt"}}>
                    {macValue.nom_mun}
                </g.Title>

                <g.Margin m="20px 0">
                    <s.CardValueV2 title="SISMAC" icon={<WalletIcon/>} color='orange'>
                        <s.CardValueRow title="S/ incentivos: " value={formattedFloat(macValue?.sem_incentivos)} />
                        <s.CardValueRow title="Incentivos: " value={formattedFloat(macValue?.incentivos)} />
                        <s.CardValueRow title="Teto mac: " value={formattedFloat(macValue?.teto_mac)} />
                    </s.CardValueV2>

                    <s.CardValueV2 title="Desacordo Sigtap" icon={<HandshakeIcon/>} color='orange' margin='12px 0 0 0'>
                        <s.CardValueRow title="Valor: " value={formattedFloat(summaryData?.sigtap.desacordo.val_total || 0)} />
                        <s.CardValueRow title="Procs: " value={summaryData?.sigtap.desacordo.qtd_procedimentos.toString() as string || "0"} />
                    </s.CardValueV2>
                </g.Margin>

            </g.ColCenter>

            
            <g.ColCenter style={{flexGrow: '.15'}}>
                <g.RowCenter margin="0 0 4px 0">
                    <Autocomplete
                        disablePortal
                        value={filtersValue.codMunicipio as any}
                        options={municipiosAutocomplete}
                        isOptionEqualToValue={
                            (option: any, value: any) => {                                
                                if (typeof value === "string") {
                                    return option.value === value

                                }

                                if (value) {
                                    return option.value === value.value
                                }

                                return false
                            }                            
                        }
                        onChange={selectMunicipio}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            );
                            }
                        }
                        onInputChange={onInputGetMunicipiosAutocomplete}
                        sx={{ flexGrow: .65, margin: '0 2px 0 0', width: 160  }}
                        renderInput={
                            (params) => {
                                // TODO: improve this using attributes from Autocomplete field
                                // This condition is use for show label after move for other page and back again for monitoring page
                                if (params.inputProps.value) {
                                    // debugger
                                    let parsed = parseInt(params.inputProps.value as string)

                                    if (!isNaN(parsed)) {
                                        params.inputProps.value = filtersValue.labelMunicipio
                                    }
                                }

                                return <TextField
                                    {...params}
                                    label="Municípios"
                                    size="small"/>
                            }
                        }
                    />

                    <FormControl sx={{ flexGrow: .35, width: 120 }} size="small">
                        <InputLabel id="pactType">Tipo</InputLabel>
                        <Select
                        labelId="pactType"
                        id="pactType"
                        label="pactType"
                        onChange={selectPactType}
                        value={filtersValue.pactType}
                        endAdornment={
                            <IconButton
                                sx={{display: filtersValue.pactType ? "": "none"}}
                                onClick={
                                    () => {
                                        setFiltersState((state: MonitoringFilters | any) => {
                                            return {...state, ...{
                                                pactType: "",
                                            }}
                                        })
                                    }
                                }>
                                    <ClearIcon/>
                            </IconButton>
                        }
                        >
                            <MenuItem value={PactType.FOR}>Encaminhador</MenuItem>
                            <MenuItem value={PactType.EXC}>Executor</MenuItem>
                            <MenuItem value={PactType.SELF}>Auto Pactuado</MenuItem>
                        </Select>
                    </FormControl>
                </g.RowCenter>

                <g.RowCenter margin="0 0 4px 0">
                    <Autocomplete
                        disablePortal
                        options={proceedingsAutocomplete}
                        value={filtersValue.codProceeding as any}
                        isOptionEqualToValue={
                            (option: any, value: any) => {
                                if (typeof value === "string") {
                                    return option.value === value
                                }

                                if (value) {
                                    return option.value === value.value
                                }

                                return false
                            }
                        }
                        renderOption={(props, option: any) => {
                            return (
                              <li {...props} key={option.id}>
                                {option.label}
                              </li>
                            );
                            }
                        }
                        onChange={selectProceeding}
                        onInputChange={onInputGetProceedingsAutocomplete}
                        sx={{ flexGrow: .65, margin: '0 2px 0 0', width: 160 }}
                        renderInput={
                            (params) => {
                                // TODO: improve this using attributes from Autocomplete field
                                // This condition is use for show label after move for other page and back again for monitoring page
                                let val = params.inputProps.value as string
                                                            
                                if (val) {
                                    if (val.match(/[xX]+\d+/) || val.match(/[xX]+\d+/) || val.match(/\d+/)){
                                        params.inputProps.value = filtersValue.labelProceeding
                                    }
                                }

                            return <TextField
                                {...params}
                                label="Procedimentos"
                                size="small"/>
                            }
                        }
                    />

                    <FormControl sx={{ flexGrow: .35, width: 120}} size="small">
                        <InputLabel id="segment">Segmento</InputLabel>
                        <Select
                            labelId="segment"
                            id="segment"
                            label="segmento"
                            onChange={selectSegment}
                            value={filtersValue.segment}
                            endAdornment={
                                <IconButton
                                    sx={{display: filtersValue.segment ? "": "none"}}
                                    onClick={
                                        () => {
                                            setFiltersState((state: MonitoringFilters | any) => {
                                                return {...state, ...{
                                                    segment: "",
                                                }}
                                            })
                                        }
                                    }>
                                        <ClearIcon/>
                                </IconButton>
                            }
                        >
                            <MenuItem value={Segments.AACA}>AACA</MenuItem>
                            <MenuItem value={Segments.AMCA}>AMCA</MenuItem>
                            <MenuItem value={Segments.HMCR}>HMCR</MenuItem>
                            <MenuItem value={Segments.HACA}>HACA</MenuItem>
                            <MenuItem value={Segments.HMCA}>HMCA</MenuItem>
                            <MenuItem value={Segments.AMCR}>AMCR</MenuItem>
                        </Select>
                    </FormControl>
                </g.RowCenter>

                <g.RowCenter margin="0 0 24px 0">
                    <FormControl sx={{ flexGrow: .65, margin: '0 2px 0 0', width: 160 }} size="small">
                        <InputLabel id="sigtap">Sigtap</InputLabel>
                        <Select
                        labelId="sigtap"
                        id="sigtap"
                        value={filtersValue.sigtap}
                        label="Sigtap"
                        onChange={selectSigtap}
                        endAdornment={
                            <IconButton
                                sx={{display: filtersValue.sigtap ? "": "none"}}
                                onClick={
                                    () => {
                                        setFiltersState((state: MonitoringFilters | any) => {
                                            return {...state, ...{
                                                sigtap: "",
                                            }}
                                        })
                                    }
                                }>
                                    <ClearIcon/>
                            </IconButton>
                        }
                        >
                            <MenuItem value={Sigtap.AGREE}>Em acordo</MenuItem>
                            <MenuItem value={Sigtap.DISAGREE}>Em desacordo</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ flexGrow: .35, width: 120}} size="small">
                        <InputLabel id="production">SIA</InputLabel>
                        <Select
                            disabled={!hasProducedsAccess}
                            labelId="production"
                            id="production"
                            value={filtersValue.produceds}
                            label="production"
                            onChange={selectProduceds}
                            endAdornment={
                                <IconButton
                                    sx={{display: filtersValue.produceds ? "": "none"}}
                                    onClick={
                                        () => {
                                            setFiltersState((state: MonitoringFilters | any) => {
                                                return {...state, ...{
                                                    produceds: "",
                                                }}
                                            })
                                        }
                                    }>
                                        <ClearIcon/>
                                </IconButton>
                            }
                            >
                                <MenuItem value={Produceds.PRODUCEDS}>Produzidos</MenuItem>
                                <MenuItem value={Produceds.DISAGREE}>Desacordo</MenuItem>
                                <MenuItem value={Produceds.NOT_PRODUCEDS}>Não Produzidos</MenuItem>
                        </Select>
                    </FormControl>
                </g.RowCenter>

                <g.RowEnd>                    
                    <Button onClick={filterMonitoring} sx={{ flexGrow: 1, padding: '4px 0', minHeight: "50px", display: "flex", justifyContent: "space-between"}} variant="contained">
                        <g.RowEnd flexGrow={.58}>
                            <b>Buscar</b>
                            &nbsp;
                            <SearchIcon />
                        </g.RowEnd>

                        <g.RowEnd flexGrow={.42}>
                            <CircularProgress style={{"color": "white", margin: "0 16px 0 0", visibility: showLoader? "visible": "hidden"}}/>
                        </g.RowEnd>
                    </Button>

                    <Button onClick={clearFilters} variant="outlined">
                        <CloseIcon />
                    </Button>
                </g.RowEnd>
            </g.ColCenter>
        </s.Container>
        
    );
}

export default Filters;
import * as request from '../../api/request';
import { API_URL } from '../../settings';
import { MacData } from '../monitoring/interfaces';
import { ChartsValues } from './interfaces';


export const getChartsValues = (codMunicipio: string, onSuccess: (data: ChartsValues) => void, onError: (error: any) => void) => {
    request.get(`${API_URL}/charts?mun=${codMunicipio}`, onSuccess, onError)
}

export const getTetoMAC = (codMunicipio: string, onSuccess: (data: MacData) => void, showErrorMessages?: boolean) => {
    request.get(`${API_URL}/sismac?cod_municipio=${codMunicipio}`, onSuccess, undefined, null, showErrorMessages)
}
import styled from 'styled-components';


export const Container = styled.div<{margin?: string}>`
    background: white;
    border-radius: 6px;
    box-shadow: 4px 4px 4px lightgrey;
    display: flex;
    margin: ${p => p.margin ? p.margin : '0px'};
    border: 1px solid lightgrey;

    @media screen and (max-width: 695px) {
        flex-grow: .9;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px;
    
    @media screen and (max-width: 695px) {
        flex-grow: 1;
    }
`

export const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    padding: 0 8px;

    @media screen and (max-width: 695px) {
        padding: 0 24px;
    }
`

export const Bars = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 50vh;
    flex-grow: 1;
`

export const CardBar = styled.div`
    min-height: 30vh;
    max-height: 40vh;
    padding: 0 0 8px 0;
    border-bottom: 1px solid #d9d9d9;
    position: relative;
`

export const Ribbon = styled.div<{color: string}>`
    background-color: ${p => (p.color ? p.color : 'green')};
    width: 2px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
`;

export const Title = styled.span<{color: string}>`
    font-size: 14pt;
    margin: auto auto auto 0;
    font-weight: bolder;
    color: ${p => (p.color ? p.color : 'green')};
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 8px 0 0 0;
`;




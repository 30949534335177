import styled from 'styled-components';

export const CloseButton = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    margin-right: 2vw;
    margin-top: 2vh;

    border: 1px solid salmon;
    border-radius: 4px;
    color: white;
    font-size: 14pt;
    font-weight: bolder;
    box-shadow: 2px 2px 2px grey;
    background: salmon;
    cursor: pointer;
`;


export const Container = styled.div`
`;
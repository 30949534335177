import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import * as s from './Navbar.style';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';

import * as toastr from '../toastr';


export enum MenuItems {
    monitoring = "monitoring",
    charts = "charts",
}

interface Props {
    activedMenu: string;
}


interface LogoTextProps {
  xs?: string
  md?: string
}
const LogoText = (props: LogoTextProps) => (
  <Typography
    variant='h2'
    component="h2"
    sx={{
      mr: 2,
      fontWeight: "bolder",
      fontSize: "26pt",
      display: { xs: props.xs || 'none', md: props.md || 'flex' }
    }}
  >
    <b>Salus8</b>
  </Typography>
)

function Navbar(props: Props) {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

    let [activeMenu, setActiveMenu] = useState<string>(props.activedMenu)

    let monitoringIsActive = activeMenu === MenuItems.monitoring;
    let chartsIsActive = activeMenu === MenuItems.charts;

    const handleClickMonitoring = () => {
      navigate("/monitoring");
      setActiveMenu(MenuItems.monitoring)
    }

    const handleClickCharts = () => {
      navigate("/charts");
      setActiveMenu(MenuItems.charts)
    }

    const handleClickLogout = () => {
        toastr.info("Deslogando...", 1000)

        setTimeout(() => {
          sessionStorage.removeItem("accessToken")
          navigate("/login");
        }, 2000)
    }

    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <LogoText/>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Menu
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                <MenuItem key={1} onClick={handleClickMonitoring}>
                    <Typography textAlign="center">Monitoramento</Typography>
                </MenuItem>

                <MenuItem key={2} onClick={handleClickCharts}>
                    <Typography textAlign="center">Gráficos</Typography>
                </MenuItem>
              </Menu>
            </Box>

            <Typography
              noWrap
              component="h2"
              sx={{
                display: { xs: 'flex', md: 'none' },
                fontSize: "26pt",
                flexGrow: 1,
                fontWeight: "bolder"
              }}
            >
              Salus8
            </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <s.BtnMenu onClick={handleClickMonitoring} margin="0 3vw 0 3vw">
                      <s.BtnMenuText style={{opacity: monitoringIsActive ? 1 : .7}}>Monitoramento</s.BtnMenuText>
                      <CircleIcon style={{margin: "auto", fontSize: "5pt", visibility: monitoringIsActive ? "visible": "hidden"}}/>
                  </s.BtnMenu>

                  <s.BtnMenu onClick={handleClickCharts}>
                      <s.BtnMenuText style={{opacity: chartsIsActive ? 1 : .7}}>Gráficos</s.BtnMenuText>
                      <CircleIcon style={{margin: "auto", fontSize: "5pt", visibility: chartsIsActive ? "visible": "hidden"}}/>
                  </s.BtnMenu>
              </Box>

            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <s.CustomAccountCircleIcon />
              </IconButton>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                  <MenuItem key={3} onClick={handleCloseNavMenu}>
                      <Typography
                        textAlign="center"
                        onClick={() => navigate("/login")}
                      >Login</Typography>
                  </MenuItem>

                  <MenuItem key={4} onClick={handleCloseNavMenu}>
                      <Typography
                        textAlign="center"
                        onClick={handleClickLogout}
                      >Logout</Typography>
                  </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
}


export default Navbar;
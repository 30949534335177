import styled from 'styled-components'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;

    @media screen and (max-width: 380px) and (max-height: 670px) {
        height: auto;
    }

    @media screen and (max-width: 1024px) and (max-height: 600px) {
        height: auto;
    }

    @media screen and (max-width: 1200px) {
        background-color: #0292b7;
    }

    justify-content: center;
`

export const LeftContainer = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media screen and (max-width: 1200px) {
        box-shadow: 4px 4px 4px darkgrey;
    }
`

export const InnerContainer = styled.div`
    width: 60%;
    
    @media screen and (max-width: 700px) {
        width: 90%;
        
    }

    margin: auto;
`

export const ImgContainer = styled.div`
    background-color: #0292b7;
    flex-grow: 1;
    background: url("/login-bg.png");
    background-size: 100% 100%;
    width: 100vh;
    height: 100%;

    @media screen and (max-width: 1200px) {
        display: none;
    }
`

export const Img = styled.img`
    width: 240px;
    margin: 0 auto 40px auto;
`;


export const InputLabel = styled.b`
    color: #555555;
`

export const Input = styled.input`
    border: none;
    background-color: #f1f1f1;
    border-radius: 2px;
    margin: 8px 0 24px 0;
    font-size: 12pt;
    outline-color: grey;
    padding: 16px;

    ::placeholder {
        color: grey;
    }

    :active {
        background-color: white;
    }
    
    :focus {
        background-color: white;
    }
`

export const PrimaryText = styled.p`
    color: #10435E;
    /* color: #0292b7; */
    font-weight: bolder;
    font-size: 20pt;
    margin: auto;
    text-align: center;
`

export const SecondaryText = styled.p`
    text-align: center;
    color: gray;
    margin-bottom: 40px;
`

export const Button = styled.button`
    border-radius: 30px;
    color: white;
    /* background-color: #0292b7; */
    background-color: #10435E;
    border: none;
    height: 60px;
    font-weight: bolder;
    font-size: 12pt;
    cursor: pointer;
    margin-top: 20px;
`

export const BtnIcon = styled(ArrowForwardIosIcon)`
    background-color: white;
    color: #0292b7;
    border-radius: 30px;
    padding: 2px;
    font-size: 11pt !important;
`
export enum PactType {
    ALL = 'all',
    FOR = 'for',
    EXC = 'exc',
    SELF = 'self'
}

export enum Produceds{
    ALL = 'all',
    PRODUCEDS = "produceds",
    NOT_PRODUCEDS = "not_produceds",
    DISAGREE = 'disagree'

}

export enum Sigtap{
    ALL = "all",
    AGREE = "agree",
    DISAGREE = "disagree"
}

export enum Segments {
    ALL="all",
    AACA="aaca",
    AMCA="amca",
    HMCR="hmcr",
    HACA="haca",
    HMCA="hmca",
    AMCR="amcr",
}